.react-datepicker {
  font-family: 'Roboto Condensed', sans-serif;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 !important;

}

.react-datepicker__header {
  background-color: #000000;
  border-bottom: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding-top: 8px;
}

.react-datepicker__current-month {
  color: #ffffff;
  border-radius: 0 !important;
}

.react-datepicker__day-name {
  color: #ffffff;
  border-radius: 0 !important;
}

.react-datepicker__day {
  color: #000000;
  border-radius: 0 !important;
}

.react-datepicker__year {
  color: #ffffff;
}

.react-datepicker__year-read-view--selected-year {
  color: rgb(255, 255, 255);
  /* Change the color to your preference */
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #000000;
  border-radius: 0 !important;
  color: #ffffff;
  /* White text for selected day */
}

.react-datepicker__day:hover {
  background-color: #4a5568;
  border-radius: 50%;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: #ffffff;
}

.react-datepicker__navigation--next {
  border-left-color: #ffffff;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: #000000;
  border: none;
  border-radius: 0 !important;
}

.react-datepicker__year-option {
  color: #ffffff;
  border-radius: 0 !important;
}

.react-datepicker__month-option {
  color: #ffffff;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
  background-color: #444444;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected {}

.react-datepicker__triangle {
  display: none !important;
}